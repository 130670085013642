p {
    margin : 0;
   
}
/* FILEPATH: /home/satish/Desktop/OEM/oem-tool-frontend/src/index.css */
/* BEGIN: abpxx6d04wxr */

/* Customize the scrollbar track */

/* width */




::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    cursor: pointer;


  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 255, 255); 
    border-radius: 10px;
    cursor: pointer;
    cursor: pointer;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(157, 157, 157); 
    border-radius: 10px;
    cursor: pointer;
    cursor: pointer;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #717171; 
    cursor: pointer;
    cursor: pointer;
  }